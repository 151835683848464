import React from 'react';
import { Text, Divider } from 'wix-ui-tpa/cssVars';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import { classes } from './TierCards.st.css';
import settingsParams from '../settingsParams';
import stylesParams, { BulletStyle, PointsTextPosition } from '../stylesParams';
import { BulletThumbnail } from './BulletThumbnail';

interface TierCardsProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
}

type DetailsItem = {
  title: string;
  description: string;
};

export const TierCards: React.FC<TierCardsProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const { t } = useTranslation();
  const showTierIcon = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.tiersShowIcon);
  const showTierDescription = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.tiersShowDescription);
  const bulletThumbnailStyle = styles.get(stylesParams.tiersBulletsStyle) as BulletStyle;
  const showBulletThumbnail = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.tiersBulletsStyle !== BulletStyle.None);
  const isUsingPointsTextPosition = (position: PointsTextPosition) =>
    styles
      .getStylesForAllBreakpoints()
      .some((breakpointStyles) => breakpointStyles.numbers.tiersPointsTextPosition === position);
  const baseTier: SimpleTier = {
    title: tiersProgramSettings.baseTierDefinition?.name ?? '',
    description: tiersProgramSettings.baseTierDefinition?.description ?? '',
    iconUrl: tiersProgramSettings.baseTierDefinition?.icon?.url ?? '',
  };

  const renderDetails = (details: DetailsItem[]) =>
    details.map((detail, index) => (
      <div key={index} className={classes.detailContainer}>
        {showBulletThumbnail && (
          <div className={classes.detailBulletContainer}>
            <BulletThumbnail type={bulletThumbnailStyle} size={17} />
          </div>
        )}
        <li aria-label={t('app.tiers.role')} className={classes.detailTextContainer}>
          <Text className={classes.detailTitle}>{detail.title}</Text>
          <Text className={classes.detailDescription}>{detail.description}</Text>
        </li>
      </div>
    ));

  const renderRules = (tierId: string | undefined | null) => {
    const rules = simpleRules.filter((rule) => rule.tierId === tierId || (!rule.tierId && !tierId));

    if (!rules.length) {
      return null;
    }

    return (
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{settings.get(settingsParams.earnPointsTitle)}</Text>
        <ul>{renderDetails(rules)}</ul>
      </div>
    );
  };

  const renderRewards = (tierId: string | undefined | null) => {
    const rewards = simpleRewards.filter((reward) => reward.tierId === tierId || (!reward.tierId && !tierId));

    if (!rewards.length) {
      return null;
    }

    return (
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{settings.get(settingsParams.redeemPointsTitle)}</Text>
        <ul>{renderDetails(rewards)}</ul>
      </div>
    );
  };

  const renderTierCards = () => {
    const cards = [baseTier, ...simpleTiers].map((tier, index) => {
      const showIcon = showTierIcon && !!tier.iconUrl;

      return (
        <li className={classes.tierCard} key={index}>
          <div className={classes.tierCardHeader}>
            <div className={classes.tierCardTitleA11y}>
              <div className={classes.tierCardTitleContainer}>
                {showIcon && (
                  <img
                    alt={t('app.image.tier-icon')}
                    aria-hidden
                    className={classes.tierCardIcon}
                    src={IMAGE_ROUTE(tier.iconUrl)}
                  />
                )}
                <Text tagName="h3" className={classes.tierCardTitle}>
                  {tier.title}
                </Text>
              </div>
              {isUsingPointsTextPosition(PointsTextPosition.AboveTitle) && (
                <Text className={classes.tierCardPointsAboveTitle}>
                  {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints ?? 0 })}
                </Text>
              )}
            </div>
            {isUsingPointsTextPosition(PointsTextPosition.UnderTitle) && (
              <Text className={classes.tierCardPointsUnderTitle}>
                {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints ?? 0 })}
              </Text>
            )}
            {showTierDescription && (
              <div className={classes.tierCardDescriptionContainer}>
                {tier.description.trim() && <Text className={classes.tierCardDescription}>{tier.description}</Text>}
                {isUsingPointsTextPosition(PointsTextPosition.UnderDescription) && (
                  <Text className={classes.tierCardPointsUnderDesc}>
                    {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints ?? 0 })}
                  </Text>
                )}
              </div>
            )}
          </div>
          <Divider className={classes.tierCardDivider} />
          <div className={classes.sectionsContainer}>
            {renderRules(tier.id)}
            {renderRewards(tier.id)}
          </div>
        </li>
      );
    });

    return cards;
  };

  const tierCards = renderTierCards();

  return <ol className={classes.root}>{tierCards}</ol>;
};
