import React, { useState } from 'react';

import { BulletArrow, BulletCheckDrawn, BulletCheckFilled, BulletCheckOutlined } from '../../../icons';
import { BulletStyle } from '../stylesParams';
import { useGetSiteColors } from './hooks/use-get-site-colors';
import { wixColorParam } from '@wix/tpa-settings';

interface BulletThumbnailProps {
  size?: number;
  color?: string;
  type: BulletStyle;
}

export const BulletThumbnail: React.FC<BulletThumbnailProps> = ({ size, color, type }) => {
  const { getSiteColors } = useGetSiteColors();
  const [defaultColor, setDefaultColor] = useState<string | undefined>(color);

  const setColor = async () => {
    const siteColors = await getSiteColors();
    const wixColor = wixColorParam('color-5')({ colors: siteColors });
    setDefaultColor(wixColor.value);
  };

  if (!color) {
    setColor();
  }

  const Bullet: React.FC = () => {
    switch (type) {
      case BulletStyle.Filled: {
        return <BulletCheckFilled size={size} color={defaultColor} />;
      }
      case BulletStyle.Outlined: {
        return <BulletCheckOutlined size={size} color={defaultColor} />;
      }
      case BulletStyle.Check: {
        return <BulletCheckDrawn size={size} color={defaultColor} />;
      }
      case BulletStyle.Arrow: {
        return <BulletArrow size={size} color={defaultColor} />;
      }
      default: {
        return <BulletCheckOutlined size={size} color={defaultColor} />;
      }
    }
  };

  return (
    <div aria-hidden>
      <Bullet />
    </div>
  );
};
