import React from 'react';

export const LayoutGrid = () => (
  <svg className="thumbnail-icon medium">
    <rect x="8" y="16" width="56" height="19" rx="3" className="cls-b1" />
    <rect x="8" y="38" width="17" height="19" rx="3" className="cls-b3" />
    <rect x="28" y="38" width="16" height="19" rx="3" className="cls-b3" />
    <rect x="47" y="38" width="17" height="19" rx="3" className="cls-b3" />
  </svg>
);
